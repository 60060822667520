import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCKRCiH59JFEsf_kAQTS57g_iNdtgifR1k",
  authDomain: "react-1-6255b.firebaseapp.com",
  projectId: "react-1-6255b",
  storageBucket: "react-1-6255b.appspot.com",
  messagingSenderId: "335713363345",
  appId: "1:335713363345:web:008691a60ec2d2020ca630",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);